import TerminalInput from "../components/Terminal";

const AboutMe = () => {
    return ( 
    <div>
         <TerminalInput/>
    </div>
);
}

export default AboutMe;