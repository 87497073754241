import Quotes from "../components/Quotes";

const Home = () => {
    return ( 
        <div>
            <h1 className="App-quotes-title">Programming Quotes</h1>
            <Quotes />
        </div> 
    );
}

export default Home;